import * as React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Container from "../components/Container"
import styled from "styled-components"
import { Link } from "gatsby"

const NotFoundPageContainer = styled(Container)`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const NotFoundPage = () => (
  <Layout showNav={false}>
    <SEO title="404: Sivua ei löydy" />
    <NotFoundPageContainer>
      <div>
        {" "}
        <h1>Sivua ei löydy</h1>
        <Link to="/">Takaisin etusivulle</Link>
      </div>
    </NotFoundPageContainer>
  </Layout>
)

export default NotFoundPage
